<template>
  <!-- Navbar -->
  <div>
    <v-overlay :value="dialog">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <nav class="navbar navbar-expand-lg bg-white navbar-absolute navbar-transparent">
      <div class="container">
        <div class="navbar-translate">
          <a
            class="navbar-brand"
            data-placement="bottom"
            @click="$router.push('/home')"
          >
            LETS Connect
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-bar top-bar" />
            <span class="navbar-toggler-bar middle-bar" />
            <span class="navbar-toggler-bar bottom-bar" />
          </button>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <div
      class="page-header header-filter"
      filter-color="black"
    >
      <div
        class="page-header-image"
        :style="{'background-image': 'url('+require('../../assets/lc_img/auth_background.jpg')+')'}"
      />
      <div class="contentWrapper">
        <div
          class="content"
          :style="{'width': '100%'}"
        >
          <div class="container">
            <div class="row">
              <div class="col-md-4 ml-auto mr-auto">
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="fas fa-comment-alt" />
                  </div>
                  <div class="description">
                    <h5 class="info-title">
                      SMS Gateway
                    </h5>
                    <p class="description">
                      Send and receive SMS messages globally. Simple yet powerful.
                    </p>
                  </div>
                </div>
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="fas fa-photo-video" />
                  </div>
                  <div class="description">
                    <h5 class="info-title">
                      Rich Messaging
                    </h5>
                    <p class="description">
                      Create and send rich multimedia experiences by RCS or messaging apps.
                    </p>
                  </div>
                </div>
                <div class="info info-horizontal">
                  <div class="icon icon-info">
                    <i class="fas fa-hand-holding-usd" />
                  </div>
                  <div class="description">
                    <h5 class="info-title">
                      No hidden charges
                    </h5>
                    <p class="description">
                      We'll beat any cheaper comparable quotes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mr-auto">
                <div class="card card-signup">
                  <div class="card-body">
                    <h4 class="card-title text-center">
                      Get a reset link
                    </h4>
                    <form
                      class="form"
                      method=""
                      action=""
                    >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="now-ui-icons users_circle-08" /></span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Your Email..."
                          autocomplete="email"
                          :value="fields.email.value"
                          @input="_updateFieldValue('email', $event.target.value)"
                          @blur="_markAsDirty('email')"
                        >
                      </div>
                      <p class="inputError">
                        {{
                          fields.email.isDirty && !fields.email.isValid? 'Please enter a valid email': ''
                        }}
                      </p>
                      <div class="card-footer text-center">
                        <a
                          href="#"
                          class="btn btn-primary btn-round btn-lg"
                          @click="_submit"
                        >Login</a>
                      </div>
                      <div class="authLinks">
                        <div
                          class="leftLink"
                          @click="$router.push('/login')"
                        >
                          Back to login
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class=" container ">
          <nav>
            <ul>
              <li>
                <a
                  href="#"
                  @click="$router.push('/home')"
                >
                  Lets Connect
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click="$router.push('/about-us')"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click="$router.push('/contact-us')"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
          <div
            id="copyright"
            class="copyright"
          >
            &copy;
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import * as firebase from 'firebase'

  export default {
    name: 'ForgetPassword',

    components: {},

    data () {
      return {
        apiEndpoint: process.env.VUE_APP_API_BASE_URL,
        apiCode: process.env.VUE_APP_API_CODE,
        firebaseCloudFunctionBaseUrl: process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL,
        dialog: false,
        fields: {
          email: {
            value: '',
            isDirty: false,
            isValid: false,
          },
        },
        isFormValid: false,
        snackbar: false,
        notification: false,
        generalError: {
          isVisible: false,
          errorText: '',
        },
      }
    },
    computed: {},
    created () {},
    methods: {
      _markAsDirty (fieldName) {
        this.fields[fieldName].isDirty = true
      },
      _updateFieldValue (fieldName, value) {
        this.fields[fieldName].value = value
        this._validateForm()
      },
      _validateForm () {
        let isFormValid = true
        Object.keys(this.fields).forEach((fieldName, index) => {
          this.fields[fieldName].isValid = true
          switch (fieldName) {
            case 'email': {
              var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              if (!emailRegex.test(String(this.fields[fieldName].value).toLowerCase())) {
                this.fields[fieldName].isValid = false
                isFormValid = false
              }
              break
            }
          }
        })
        this.isFormValid = isFormValid
      },
      _makeAllFieldDirty () {
        Object.keys(this.fields).forEach((fieldName, index) => {
          this.fields[fieldName].isDirty = true
        })
      },
      async _submit (e) {
        this.generalError = {
          isVisible: false,
          errorText: '',
        }
        e.preventDefault()
        this._makeAllFieldDirty()
        this._validateForm()
        if (this.isFormValid) {
          this.dialog = true
          try {
            await firebase.default.auth().sendPasswordResetEmail(this.fields.email.value)
            this.dialog = false
            this.notification = 'Reset link sent to your mail'
            this.snackbar = true
          } catch (error) {
            this.dialog = false
            this.notification = error.message
            this.snackbar = true
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../assets/bootstrap.min.css';
@import '../../assets/now-ui-kit.css';
.page-header {
  max-height: unset!important;
}
.social {
  button {
    margin: 0 5px;
  }
}
.inputError {
  font-size: 0.7rem;
  margin-top: -5px;
  margin-bottom: 6px;
  color: red;
  font-weight: 600;
  height: 15px;
}
.card-title {
  color: black;
  margin-bottom: 8rem!important;
}
.info-horizontal {
  margin-top: -6rem;
}
.contentWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
      margin-top: 8rem;
  }
}
.authLinks {
  padding: 0 25px;
  justify-content: space-around;
  align-items: center;
  margin-top: -1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  color: rgb(66, 66, 66);
  font-size: 0.8rem;
  div {
    cursor: pointer;
  }
}
</style>
